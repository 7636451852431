<script>
import appConfig from "@/app.config";

import { required } from "vuelidate/lib/validators";

/**
 * Form Common Questions component
 */
export default {
  page: {
    title: "Formulário de Perguntas Frequentes",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {},
  data() {
    return {
      form: {
        question: '',
        answer: '',
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    form: {
      question: { required },
      answer: { required },
    },
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row mb-3">
      <div class="col">
        <label class="form-label" for="title">Pergunta</label>
        <input
          id="question"
          v-model="form.question"
          type="text"
          class="form-control"
          placeholder="Informe a pergunta"
          :class="{
            'is-invalid': submitted && $v.form.question.$error,
          }"
        />
        <div
          v-if="submitted && $v.form.question.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.question.required"
            >Por favor, digite a pergunta.</span
          >
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <label class="form-label" for="title">Resposta</label>
        <textarea
          id="answer"
          v-model="form.answer"
          type="text"
          rows="6"
          class="form-control"
          placeholder="Informe a resposta"
          :class="{
            'is-invalid': submitted && $v.form.answer.$error,
          }"
        />
        <div
          v-if="submitted && $v.form.answer.$error"
          class="invalid-feedback"
        >
          <span v-if="!$v.form.answer.required"
            >Por favor, digite a resposta.</span
          >
        </div>
      </div>
    </div>
    <br />
    <div class="mt-2 text-end">
      <b-button variant="light" @click="handleNewCad(false)">Fechar</b-button>
      <b-button type="submit" variant="info" class="ms-1">Cadastrar</b-button>
    </div>
  </form>
</template>
