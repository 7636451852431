const tableData = [
  {
    id: 1,
    question: 'Recruiting Mantotalname',
    answer: 'dont know',
    status: 'A',
  }, {
    id: 2,
    question: 'Quality Engineer',
    answer: 'dont know',
    status: 'I',
  }, {
    id: 3,
    question: 'Senior Editor',
    answer: 'dont know',
    status: 'A',
  }, {
    id: 4,
    question: 'Programmer II',
    answer: 'dont know',
    status: 'A',
  },
  {
    id: 5,
    question: 'Data Coordiator',
    answer: 'dont know',
    status: 'I',
  }, {
    id: 6,
    question: 'Data Coordiator',
    answer: 'dont know',
    status: 'I',
  },
]

export { tableData };
